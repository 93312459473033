import React from "react";
import empty from "../../assets/images/illustration/chat.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function EmptyChat(props) {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 40, color: "#E38B33" }} spin />
  );
  return (
    <div className="empty__chat">
      <div
        className={
          props.loading === true
            ? "chat__user_loading"
            : "chat__user_loading d-none"
        }
      >
        <Spin indicator={antIcon} />
      </div>
      <img src={empty} alt="" />
      <h6>Mulai Chat dengan pilih toko yang ingin kamu lihat chatnya</h6>
    </div>
  );
}

export default EmptyChat;
