import React, { useState } from "react";
import "../../styles/login.css";
import logo from "../../assets/images/logo.svg";
import { Button, Form, Input, Modal, Spin } from "antd";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_PROD } from "../../url/api";
import { JWT_KEY } from "../../url/jwt";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Login() {
  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const login = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (login) {
      return navigate("/chat");
    }
  }, [login, navigate]);

  const processLogin = (values) => {
    setLoading(true);

    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
      },
    };

    axios
      .post(`${API_PROD}login`, values, config)
      .then((res) => {
        if (res.status === 200) {
          let storeData = {
            name: res.data.user.name,
            token: res.data.token,
          };

          localStorage.setItem("chat", JSON.stringify(storeData));
          dispatch(
            loginAction({
              name: res.data.user.name,
              token: res.data.token,
              login: true,
            })
          );
        }
      })
      .catch((err) => {
        setErrorMsg(
          "Email/Password salah. Silahkan coba lagi dengan email/password yang benar"
        );
        setLoading(false);
        setModalError(true);
      });
  };

  /**
   * Set Icon for Loading
   */
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />
  );

  return (
    <>
      <div className={"login__wrapper"}>
        <div className={"login__form"}>
          <div className="text-center">
            <img src={logo} alt="" />
            <h3>Login</h3>
          </div>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={processLogin}
            autoComplete="off"
            layout="vertical"
            className={"form__input"}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Mohon masukkan Email anda!",
                },
                {
                  type: "email",
                  message: "Mohon masukkan format email yang benar!",
                },
              ]}
            >
              <Input className={"input_field"} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Mohon masukkan password!",
                },
              ]}
            >
              <Input.Password className={"input_field"} />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              {loading ? (
                <Button type="primary" className={"form__button"}>
                  <Spin indicator={antIcon} />
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className={"form__button"}
                >
                  Masuk
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* ============================================================
      ========================= Modal Error ==========================
      ================================================================ */}

      <Modal
        centered
        visible={modalError}
        onOk={() => setModalError(false)}
        onCancel={() => setModalError(false)}
        title=""
        footer={null}
        className={"modal_error"}
      >
        <CloseCircleOutlined className={"modal_error_ico"} />
        <h3>Gagal Login!</h3>
        <p>{errorMsg}</p>
      </Modal>
    </>
  );
}

export default Login;
