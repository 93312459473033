import React, { useState, useEffect, useRef } from "react";
import "../../styles/chat.css";
import { Select, Badge, Spin, Input, Form, Image } from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import tokped from "../../assets/images/tokopedia.svg";
import shopee from "../../assets/images/shopee.svg";
import userdummy from "../../assets/images/illustration/user-dummy.png";
import { useSelector } from "react-redux";
import EmptyChat from "./EmptyChat";
import axios from "axios";
import { API_PROD } from "../../url/api";
import { JWT_KEY } from "../../url/jwt";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Logout from "../../components/station/Logout";

function Chat() {
  const [storeList, setStoreList] = useState(null);
  const [userList, setUserList] = useState(null);
  const [chatList, setChatList] = useState(null);
  const [storeLoading, setStoreLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [storeIdx, setStoreIdx] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [activePlatform, setActivePlatform] = useState(null);
  const [unreadChat, setUnreadChat] = useState(0);
  const [userName, setUserName] = useState("");
  const [userPageIdx, setUserPageIdx] = useState(0);

  const [loadingSendMsg, setLoadingSendMsg] = useState(false);

  const name = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const login = useSelector((state) => state.auth.login);

  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const chatRef = useRef(null);
  const userRef = useRef(null);

  let navigate = useNavigate();

  useEffect(() => {
    if (login === false) {
      return navigate("/");
    }
  }, [login, navigate]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 40, color: "#E38B33" }} spin />
  );

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "#fff" }} spin />
  );

  useEffect(() => {
    getStoreList();
    getTotalUnread();
    // eslint-disable-next-line
  }, []);

  // scroll to bottom when user list loaded
  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chatList]);

  // scroll to top when chat list loaded
  // useEffect(() => {
  //   userRef.current?.scrollIntoView({ behavior: "auto" });
  // }, [userList]);

  // Get total chat unread every 15 sec
  useEffect(() => {
    const interval = setInterval(() => {
      getTotalUnread();
      getStoreList();
    }, 15000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  // Get count of all unread chat
  const getTotalUnread = () => {
    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
        Authorization: "Bearer" + token,
      },
    };

    axios
      .get(`${API_PROD}get-total-unread`, config)
      .then((res) => {
        if (res.status === 200) {
          setUnreadChat(res.data.totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get list of all store connected
  const getStoreList = () => {
    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
        Authorization: "Bearer" + token,
      },
    };

    axios
      .get(`${API_PROD}chat-store`, config)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setStoreList(res.data.store);
          setStoreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setStoreLoading(false);
      });
  };

  // Render list of all store
  const renderStoreList = () => {
    if (storeList !== null) {
      return storeList.map((el, idx) => {
        return (
          <Option value={el.id} key={el.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={el.Platform === "Shopee" ? shopee : tokped}
                  alt=""
                  style={{ marginRight: "10px" }}
                />{" "}
                {el.store_name}
              </div>
              <Badge
                className="site-badge-count-109"
                count={el.count}
                style={{
                  backgroundColor: "#E38B33",
                }}
              />
            </div>
          </Option>
        );
      });
    }
  };

  // Handle on choose store to get user list
  const chooseStore = (value) => {
    setStoreIdx(value);
    setChatList(null);
    getUserList(value);
  };

  // Get list of all user from that store
  const getUserList = (id) => {
    setUserLoading(true);
    setUserPageIdx(0);
    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
        Authorization: "Bearer" + token,
      },
    };

    axios
      .get(`${API_PROD}get-chat-person/${id}`, config)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setUserList(res.data.data);
          setUserPageIdx(res.data.current_page);
          setUserLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setUserLoading(false);
      });
  };

  const loadUserList = () => {
    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
        Authorization: "Bearer" + token,
      },
    };

    axios
      .get(
        `${API_PROD}get-chat-person/${storeIdx}?page=${userPageIdx + 1}`,
        config
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // setUserList(res.data);
          setUserPageIdx(res.data.current_page);
          let arrUser = res.data.data;

          return arrUser.map((el) => {
            return setUserList((userList) => [...userList, el]);
          });
          // setUserLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // setUserLoading(false);
      });
  };

  // Render list of user from that store
  const renderUserList = () => {
    if (userList !== null) {
      return userList.map((el, idx) => {
        return (
          <div
            className={
              activeUser === el.last_customer_message_id
                ? "chat__item active"
                : "chat__item"
            }
            key={idx}
            onClick={() =>
              chooseUser(el.last_customer_message_id, el.name, el.platform)
            }
          >
            <div className="d-flex">
              <div className="images">
                <img src={userdummy} alt="" />
              </div>
              <div>
                <div className="chat__item_name">{el.name}</div>
                <div
                  className="chat__item_preview"
                  dangerouslySetInnerHTML={{ __html: el.last_message }}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column"
              style={{ position: "relative" }}
            >
              <div
                className="d-flex justify-content-end"
                style={{ marginBottom: "5px" }}
              >
                <Badge
                  className="site-badge-count-109"
                  count={el.unread_message}
                  style={{
                    backgroundColor: "#E38B33",
                  }}
                />
              </div>
              <span style={{ opacity: "0.3", fontSize: "11px" }}>
                {moment(el.last_message_time).calendar()}
              </span>
            </div>
          </div>
        );
      });
    }
  };

  const chooseUser = (id, name, platform) => {
    setUserName(name);
    setActiveUser(id);
    setActivePlatform(platform);
    getChatList(id);
    getStoreList();
    getTotalUnread();
  };

  // Get list of all chat message from specific user
  const getChatList = (id) => {
    // setChatList(null);
    setChatLoading(true);
    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
        Authorization: "Bearer" + token,
      },
    };

    axios
      .get(`${API_PROD}get-chat-message/${id}`, config)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setChatList(res.data);
          setChatLoading(false);
          // getUserList(storeIdx);
        }
      })
      .catch((err) => {
        console.log(err);
        setChatLoading(false);
      });
  };

  // Render list of chat message from specific user
  const renderChatList = () => {
    if (chatList !== null) {
      return chatList.data.map((el, idx) => {
        if (el.reply.length === 0) {
          return (
            <div className="talk-bubble tri-right left-top customer" key={idx}>
              <div className="  ">
                {el.type === "text" ? (
                  <p dangerouslySetInnerHTML={{ __html: el.msg }} />
                ) : (
                  // <img src={el.msg} alt="" />
                  <Image src={el.msg} />
                )}
                <p className="text-end" style={{ margin: 0, opacity: "0.5" }}>
                  {el.time}
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div key={idx}>
              <div
                className="talk-bubble tri-right left-top customer"
                key={idx}
              >
                <div className="talktext">
                  {el.type === "text" ? (
                    <p dangerouslySetInnerHTML={{ __html: el.msg }} />
                  ) : (
                    <img src={el.msg} alt="" />
                  )}
                  <p className="text-end" style={{ margin: 0, opacity: "0.5" }}>
                    {el.time}
                  </p>
                </div>
              </div>
              {el.reply.map((val, ind) => {
                return (
                  <div className="text-end" key={ind}>
                    <div className="talk-bubble tri-right right-top cs">
                      <div className="talktext">
                        <p dangerouslySetInnerHTML={{ __html: val.msg }} />
                        <p
                          className="text-end"
                          style={{ margin: 0, opacity: "0.5" }}
                        >
                          {val.nama} -{" "}
                          {/* {moment(val.created_at).format("hh:mm a")} */}
                          {moment(val.created_at).calendar()}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }

        // if (el.role === "customer") {
        //   return (
        //     <div className="talk-bubble tri-right left-top customer" key={idx}>
        //       <div className="talktext">
        //         <p dangerouslySetInnerHTML={{ __html: el.msg }} />
        //         <p className="text-end" style={{ margin: 0, opacity: "0.5" }}>
        //           {el.time}
        //         </p>
        //       </div>
        //     </div>
        //   );
        // } else {
        //   return (
        //     <div className="text-end" key={idx}>
        //       <div className="talk-bubble tri-right right-top cs">
        //         <div className="talktext">
        //           <p dangerouslySetInnerHTML={{ __html: el.msg }} />
        //           <p className="text-end" style={{ margin: 0, opacity: "0.5" }}>
        //             {el.nama} - {el.time}
        //           </p>
        //         </div>
        //       </div>
        //     </div>
        //   );
        // }
      });
    }
  };

  // Send message from user input
  const sendMessage = (values) => {
    if (values.message !== "") {
      setLoadingSendMsg(true);

      const config = {
        headers: {
          "Jwt-Key": JWT_KEY,
          Authorization: "Bearer" + token,
        },
      };

      const data = {
        message: values.message,
        id: activeUser,
        platform: activePlatform,
      };

      // console.log(chatList);
      // setLoadingSendMsg(false);

      axios
        .post(`${API_PROD}balas-chat`, data, config)
        .then((res) => {
          if (res.status === 200) {
            form.resetFields();
            setLoadingSendMsg(false);
            getChatList(activeUser);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingSendMsg(false);
        });
    }
  };

  return (
    <>
      <div className="chat__wrapper">
        <div className="chat__header">
          <h3>Hello, {name}!</h3>
          {/* <button onClick={getStoreList}>Logout</button> */}
          <Logout />
        </div>
        <div className="chat__store">
          <h6>
            Total Chat belum dibaca: <span>{unreadChat}</span>
          </h6>
          <Select
            placeholder="Pilih Toko"
            onChange={chooseStore}
            className="chat__store_dd"
            loading={storeLoading}
          >
            {storeList !== null ? renderStoreList() : ""}
          </Select>
        </div>

        {userList === null ? (
          <EmptyChat loading={userLoading} />
        ) : (
          <div className="row chats">
            <div
              className="col-lg-4"
              style={{
                paddingLeft: 0,
                paddingRight: "5px",
                marginBottom: "15px",
              }}
            >
              <div className="chat__user">
                <div
                  className={
                    userLoading === true
                      ? "chat__user_loading"
                      : "chat__user_loading d-none"
                  }
                >
                  <Spin indicator={antIcon} />
                </div>
                <div className="chat__user_header">
                  <p>Chat User</p>
                  <ReloadOutlined
                    style={{ fontSize: "18px" }}
                    onClick={() => getUserList(storeIdx)}
                  />
                </div>

                <div className="chat__user_body">
                  <div ref={userRef} />
                  {userList ? renderUserList() : ""}

                  <div className="chat__user_loadmore">
                    <button onClick={loadUserList}>Load More</button>
                  </div>
                </div>
              </div>
            </div>

            {chatList === null ? (
              <div
                className="col-lg-8"
                style={{ paddingRight: 0, paddingLeft: "5px" }}
              >
                <EmptyChat />
              </div>
            ) : (
              <div
                className="col-lg-8"
                style={{ paddingRight: 0, paddingLeft: "5px" }}
              >
                <div className="chat__message">
                  <div
                    className={
                      chatLoading === true
                        ? "chat__user_loading"
                        : "chat__user_loading d-none"
                    }
                  >
                    <Spin indicator={antIcon} />
                  </div>
                  <div className="chat__message_header">
                    <p>{userName}</p>
                    <ReloadOutlined
                      style={{ fontSize: "18px" }}
                      onClick={() => getChatList(activeUser)}
                    />
                  </div>
                  <div className="chat__message_body">
                    <div className="chat__message_item">{renderChatList()}</div>
                    <div ref={chatRef} />
                  </div>
                  <div className="chat__message_footer">
                    <Form
                      form={form}
                      name="chats"
                      onFinish={sendMessage}
                      autoComplete="off"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Form.Item
                        name="message"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Masukkan pesan kamu terlebih dahulu",
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                      </Form.Item>
                      <Form.Item>
                        {loadingSendMsg === true ? (
                          <button>
                            <Spin indicator={loadingIcon} />
                          </button>
                        ) : (
                          <button htmltype="submit">Kirim</button>
                        )}
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Chat;
