import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  token: "",
  login: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      return action.payload
    },
    logoutAction: () => initialState,
  },
});

export const { loginAction, logoutAction } = authSlice.actions;

export default authSlice.reducer;
