import React, { useState } from "react";
import axios from "axios";
import { API_PROD } from "../../url/api";
import { JWT_KEY } from "../../url/jwt";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { logoutAction } from "../../redux/auth";

function Logout() {
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const processLogout = () => {
    setLoading(true);
    const config = {
      headers: {
        "Jwt-Key": JWT_KEY,
        Authorization: "Bearer " + token,
      },
    };

    axios
      .get(`${API_PROD}logout`, config)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          dispatch(logoutAction());
          localStorage.removeItem("chat");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 30, color: "#F2A541" }} spin />
  );

  return (
    <>
      {loading ? (
        <button>
          <Spin indicator={antIcon} />
        </button>
      ) : (
        <button onClick={processLogout}>Log Out</button>
      )}
    </>
  );
}

export default Logout;
