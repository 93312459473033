import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/login";
import Chat from "./pages/chat/chat";
import { useDispatch } from "react-redux";
import { loginAction } from "./redux/auth";
import CacheBuster from "./CacheBuster";

function App() {
  const userData = JSON.parse(localStorage.getItem("chat"));
  const dispatch = useDispatch();

  if (userData) {
    // console.log(userData);
    dispatch(
      loginAction({
        name: userData.name,
        token: userData.token,
        login: true,
      })
    );
  }

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/chat" element={<Chat />} />
            </Routes>
          </BrowserRouter>
        );
      }}
    </CacheBuster>
  );
}

export default App;
